import createArray from '../helpers/array_helper.js'

export default function MatrixBodyFacade(params) {
    var self = null;
    var win = null;
    var body = null;
    var wrapper = null;
    var loadingWidget = null;
    var mechanic = null;

    var onLoadRunnables = [];
    var onResizeRunnables = [];
    var listeners = [];

    var holders = [];
    var holderMatrix = []; // double-array

    var m = 3;
    var n = 3;

    this.getM = function() {
        return m;
    }

    this.getN = function() {
        return n;
    }

    this.getMechanic = function() {
        return mechanic;
    }

    this.getWindow = function() {
        return win;
    }

    this.getBody = function() {
        return body;
    }

    this.getWrapper = function() {
        return wrapper;
    }

    this.addListener = function(l) {
        listeners.push(l);
    }

    this.removeListener = function(l) {
        listeners.removeItem(l);
    }

    this.addHiddenWhenLoadingElement = function(e) {
        loadingWidget.addDependent($(e));
    }

    this.removeHiddenWhenLoadingElement = function(e) {
        loadingWidget.removeDependent(e);
    }

    this.getLoadingWidget = function() {
        return loadingWidget;
    }

    this.isLoading = function() {
        return loadingWidget.isLoading();
    }

    this.setLoading = function(l) {
        loadingWidget.setLoading(l);
    }

    this.onLoad = function(l) {
        onLoadRunnables.push(l);
    }

    this.onResize = function(l) {
        onResizeRunnables.push(l);
    }

    this.setMechanic = function(m) {
        if (m == null)
            return;

        mechanic = m;

        body.attr("data-center-i", m.getCenter().i);
        body.attr("data-center-j", m.getCenter().j);

        mechanic.setFacade(self);

        $(window).resize(function() {
            mechanic.goCenter();
        });
    }

    this.getHolders = function() {
        return holders;
    }

    this.getHolder = function(i,j) {
        if (i >= n || j >= m)
            return null;

        if (i < 0 || j < 0)
            return null;

        return holderMatrix[i][j];
    }

    this.findHolder = function(identifier) {
        var target = null;

        holders.forEach(function(vh) {
            if (vh.getIdentifier() == identifier)
                target = vh;
        });

        return target;
    }

    this.addHolder = function(h, i, j) {
        var holderIsSomewhere = self.findHolder(h.getIdentifier()) != null;
        var slotIsEmpty = holderMatrix[i][j] == null || holderMatrix[i][j].getBuilder().getType() == "empty";

        if (holderMatrix[i][j] == null || (!holderIsSomewhere && slotIsEmpty)) {
            h.create();
            h.set(i, j);
            holders.push(h);

            if (holderMatrix[i][j] != null) {
                holderMatrix[i][j].getElement().after(h.getElement());

                holderMatrix[i][j].getElement().remove();

                holderMatrix[i][j].set(null, null);
                holders.removeItem(holderMatrix[i][j]);
            } else {
                body.append(h.getElement());
            }

            holderMatrix[i][j] = h;

            h.didAttachToWindow(self);

            if (mechanic != null && i == mechanic.getCenter().i && j == mechanic.getCenter().j)
                mechanic.goCenter();
        }
    }

    this.removeHolder = function(h, i ,j) {
        var holderIsSomewhere = self.findHolder(h.getIdentifier()) != null;
        var slotIsEmpty = holderMatrix[i][j].getBuilder().getType() == "empty";

        if ((h != null && h.getBuilder().getType() != "empty") && (!holderIsSomewhere || slotIsEmpty))
            return;

        h.getElement().remove();

        h.set(null, null);
        holders.removeItem(h);

        if (h.getBuilder().getType() != "empty")
            self.addHolder(createEmptyInnerHolderAt(i, j), i, j);
    }

    var createEmptyInnerHolderAt = function(i, j) {
        var emptyId = "empty_{i}_{j}".format({i: i, j: j});

        return new MatrixInnerHolderComponent({
            identifier: emptyId + "_ihc",
            containerSelector: "." + emptyId + ".empty_inner_holder.inner_holder",
            contentSelector: ".empty_inner_holder_container_wrapper.inner_holder_container_wrapper",
            builder: new EmptyMatrixInnerHolderBuilder()
        });
    }

    this.init = function() {
        body.attr("data-m", m);
        body.attr("data-n", n);

        for (var i = 0; i < n; i++) {
            for (var j = 0; j < m; j++) {
                var h = createEmptyInnerHolderAt(i,j);

                self.addHolder(h, i, j);
            }
        }
    };

    var __construct = function(that) {
        self = that;
        win = $(window);
        body = $(params.bodySelector);
        wrapper = $(params.wrapperSelector);
        loadingWidget = new LoadingWidget(params.loadingSelector);

        m = params.m;
        n = params.n;

        holderMatrix = createArray(n,m);

        self.init();

        win.on('load',function() {
            onLoadRunnables.forEach(function(f) {
                f(that, win);
            });
        });

        win.on('resize', function() {
            setTimeout(function() {
                mbf.getMechanic().goCenter();
                mbf.getMechanic().updateControls(mbf, mbf.getMechanic().getCurrent());
            }, 1000);
        })
    }(this)
}


