export default class SwipeFix {
    fix() {
        $.event.special.swipe.horizontalDistanceThreshold = (screen.availWidth) / (isMobile.any() ? 3 : 10);
        $.event.special.swipe.verticalDistanceThreshold = (screen.availHeight) / 4;

        $.event.special.swipe.handleSwipe = function( start, stop, thisObject, origTarget ) {
            if ( stop.time - start.time < $.event.special.swipe.durationThreshold ) {
                var horSwipe = Math.abs( start.coords[0] - stop.coords[0] ) > $.event.special.swipe.horizontalDistanceThreshold;
                var verSwipe = Math.abs( start.coords[1] - stop.coords[1] ) > $.event.special.swipe.verticalDistanceThreshold;
                if( horSwipe != verSwipe ) {
                    var direction;
                    if(horSwipe)
                        direction = start.coords[0] > stop.coords[0] ? "swipeleft" : "swiperight";
                    else
                        direction = start.coords[1] > stop.coords[1] ? "swipeup" : "swipedown";
                    $.event.trigger($.Event( "swipe", { target: origTarget, swipestart: start, swipestop: stop }), undefined, thisObject);
                    $.event.trigger($.Event( direction, { target: origTarget, swipestart: start, swipestop: stop }), undefined, thisObject);
                    return true;
                }
                return false;
            }
            return false;
        };

        $.each({
            swipeup: "swipe.up",
            swipedown: "swipe.down"
        }, function( event, sourceEvent ) {
            $.event.special[ event ] = {
                setup: function() {
                    $( this ).bind( sourceEvent, $.noop );
                },
                teardown: function() {
                    $( this ).unbind( sourceEvent );
                }
            };
        });
    }
}