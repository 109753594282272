export default function MatrixInnerHolderBuilder(w, params) {
    var self = null;
    var wrapped = null;

    var windowParams = {
        coordinates: { i: 1, j: 1 }
    };

    this.getType = function() {
        return "matrix";
    }

    this.getParams = function() {
        return windowParams;
    }

    this.afterAttach = function(holder) {

    }

    this.animateIn = function(holder) {

    }

    this.animateOut = function(holder) {

    }

    this.build = function(holder) {
        var containerSelector = holder.getContainerSelector();
        var ele = $("<div></div>");

        if (containerSelector.search("#") >= 0) {
            ele.attr("id", containerSelector.replace(/#/g, ""));
        } else if (containerSelector.search(".") >= 0) {
            ele.addClass(containerSelector.replace(/\./g, " "));
        }

        ele.append(createContentHolder(holder));

        if (wrapped != null) {
            return wrapped.build(holder);
        }

        return ele;
    };

    var createContentHolder = function(holder) {
        var ch = $("<section></section>");
        var sel = holder.getContentSelector();

        if (sel.search("#") >= 0) {
            ch.attr("id", sel.replace(/#/g, ""));
        } else if (sel.search(".") >= 0) {
            ch.addClass(sel.replace(/\./g, " "));
        }

        return ch;
    };

    var __construct = function (that) {
        self = that;
        wrapped = w;

        if (typeof(params) != 'undefined')
            windowParams = params;
    }(this)
}







