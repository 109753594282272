export default function EmptyMatrixInnerHolderBuilder(w, params) {
    var self = null;
    var wrapped = null;

    var windowParams = {
    };

    this.getType = function() {
        return "empty";
    }

    this.getParams = function() {
        return windowParams;
    }

    this.afterAttach = function(holder) {

    }

    this.animateIn = function(holder) {

    }

    this.animateOut = function(holder) {

    }

    this.build = function(holder) {
        var containerSelector = holder.getContainerSelector();
        var ele = $("<div></div>");

        if (containerSelector.search("#") >= 0) {
            ele.attr("id", containerSelector.replace(/#/g, ""));
        } else if (containerSelector.search(".") >= 0) {
            ele.addClass(containerSelector.replace(/\./g, " "));
        }

        if (wrapped != null) {
            return wrapped.build(holder);
        }

        return ele;
    }

    var __construct = function (that) {
        self = that;
        wrapped = w;

        if (typeof(params) != 'undefined')
            windowParams = params;
    }(this)
}