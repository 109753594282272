export default function ChildNavigationStrategy(i) {
    var self = null;
    var interceptor = null;

    this.goLeft = function(f) {
        if (!!interceptor && !!interceptor.goLeft)
            if (interceptor.goLeft(f))
                return true;

        var h = f.getMechanic().getCurrent();
        var scrollable = h.getElement().find(".free_width_inner_holder_container");

        if (scrollable.length == 0)
            return false;

        if (scrollable.scrollLeft() <= 0)
            return false;

        /* TODO: mecânica de navegação horizontal (isHorizontalScrollEnabled) */
        if (true) {
            scrollable.stop().animate({
                scrollLeft: scrollable.scrollLeft() - scrollable.width()
            }, 300, 'swing', function () {
                f.getMechanic().updateControls(f, h);
            });
        } else {
            f.getMechanic().updateControls(f, h);
        }

        return true;
    };

    this.goRight = function(f) {
        if (!!interceptor && !!interceptor.goRight)
            if (interceptor.goRight(f))
                return true;

        var h = f.getMechanic().getCurrent();
        var scrollable = h.getElement().find(".free_width_inner_holder_container");
        var content = h.getElement().find(".container_content");

        if (scrollable.length == 0)
            return false;

        if (scrollable.scrollLeft() + scrollable.width() >= content.width())
            return false;

        /* TODO: mecânica de navegação horizontal (isHorizontalScrollEnabled) */
        if (true) {
            scrollable.stop().animate({
                scrollLeft: scrollable.scrollLeft() + scrollable.width()
            }, 300, 'swing', function () {
                f.getMechanic().updateControls(f, h);
            });
        } else {
            f.getMechanic().updateControls(f, h);
        }

        return true;
    };

    this.goUp = function(f) {
        if (!!interceptor && !!interceptor.goUp)
            if (interceptor.goUp(f))
                return true;

        var h = f.getMechanic().getCurrent();
        var scrollable = h.getElement().find(".free_height_inner_holder_container");

        if (scrollable.length == 0)
            return false;

        if (scrollable.scrollTop() <= 0)
            return false;

        if (f.getMechanic().getVerticalScrollEnabled()) {
            scrollable.stop().animate({
                scrollTop: scrollable.scrollTop() - scrollable.height()
            }, 300, 'swing', function () {
                f.getMechanic().updateControls(f, h);
            });
        } else {
            f.getMechanic().updateControls(f, h);
        }

        return true;
    };

    this.goDown = function(f) {
        if (!!interceptor && !!interceptor.goDown)
            if (interceptor.goDown(f))
                return true;

        var h = f.getMechanic().getCurrent();
        var scrollable = h.getElement().find(".free_height_inner_holder_container");
        var content = h.getElement().find(".container_content");

        if (scrollable.length == 0)
            return false;

        if (scrollable.scrollTop() + scrollable.height() >= content.height())
            return false;

        if (f.getMechanic().getVerticalScrollEnabled()) {
            scrollable.stop().animate({
                scrollTop: scrollable.scrollTop() + scrollable.height()
            }, 300, 'swing', function() {
                f.getMechanic().updateControls(f, h);
            });
        } else {
            f.getMechanic().updateControls(f, h);
        }

        return true;
    };

    this.goCenter = function(f) {
        if (!!interceptor && !!interceptor.goCenter)
            if (interceptor.goCenter(f))
                return true;

        return false;
    }

    this.go = function(f, i,j, duration) {
        if (!!interceptor && !!interceptor.go)
            if (interceptor.go(f, i, j, duration))
                return true;

        return false;
    };

    var __construct = function(that) {
        self = that;

        interceptor = i;
    }(this)
}
