export default function ChildNavigationControls(i) {
    var self = null;
    var interceptor = null;

    this.updateLeft = function(f, h) {
        if (!!interceptor && !!interceptor.updateLeft)
            if (interceptor.updateLeft(f, h))
                return true;

        var scrollable = h.getElement().find(".free_width_inner_holder_container");
        var control = f.getWrapper().find(".matrix_controls_body .matrix_control_left");

        if (scrollable.length == 0 || scrollable.scrollLeft() <= 0) {
            control.removeClass("scale_down");

            return false;
        }

        control.addClass("scale_down");
        control.removeClass("disabled");

        control.off('click');
        control.click(function() {
            scrollable.stop().animate({
                scrollLeft: scrollable.scrollLeft() - scrollable.width()
            }, 300, 'swing', function() {
                f.getMechanic().updateControls(f, h);
            });
        });

        return true;
    };

    this.updateRight = function(f, h) {
        if (!!interceptor && !!interceptor.updateRight)
            if (interceptor.updateRight(f, h))
                return true;

        var scrollable = h.getElement().find(".free_width_inner_holder_container");
        var content = h.getElement().find(".container_content");
        var control = f.getWrapper().find(".matrix_controls_body .matrix_control_right");

        if (scrollable.length == 0 || (scrollable.scrollLeft() + scrollable.width() >= content.width())) {
            control.removeClass("scale_down");

            return false;
        }

        control.addClass("scale_down");
        control.removeClass("disabled");

        control.off('click');
        control.click(function() {
            scrollable.stop().animate({
                scrollLeft: scrollable.scrollLeft() + scrollable.width()
            }, 300, 'swing', function() {
                f.getMechanic().updateControls(f, h);
            });
        });

        return true;
    };

    this.updateTop = function(f, h) {
        if (!!interceptor && !!interceptor.updateTop)
            if (interceptor.updateTop(f, h))
                return true;

        var scrollable = h.getElement().find(".free_height_inner_holder_container");
        var control = f.getWrapper().find(".matrix_controls_body .matrix_control_up");

        if (scrollable.length == 0 || scrollable.scrollTop() <= 0) {
            control.removeClass("scale_down");

            return false;
        }

        control.addClass("scale_down");
        control.removeClass("disabled");

        control.off('click');
        control.click(function() {
            scrollable.stop().animate({
                scrollTop: scrollable.scrollTop() - scrollable.height()
            }, 300, 'swing', function() {
                f.getMechanic().updateControls(f, h);
            });
        });

        return true;
    };

    this.updateTopbar = function(f, h) {
        if (!!interceptor && !!interceptor.updateTopbar)
            if (interceptor.updateTopbar(f, h))
                return true;

        var scrollable = h.getElement().find(".free_height_inner_holder_container");
        var toolbar = f.getWrapper().find(".matrix_controls_body .topbar_matrix_control");

        if (scrollable.length == 0)
            return false;

        if (scrollable.scrollTop() <= 0)
            return false;

        scrollable.off('scroll');
        scrollable.scroll(function() {
            f.getMechanic().updateControls(f, h);
        });

        toolbar.addClass("disabled");
        toolbar.off('click');

        return true;
    };

    this.updateBotbar = function(f, h) {
        if (!!interceptor && !!interceptor.updateBotbar)
            if (interceptor.updateBotbar(f, h))
                return true;

        var scrollable = h.getElement().find(".free_height_inner_holder_container");
        var botbar = f.getWrapper().find(".matrix_controls_body .botbar_matrix_control");

        if (scrollable.length == 0)
            return false;

        if (scrollable.scrollTop() <= 0)
            return false;

        scrollable.off('scroll');
        scrollable.scroll(function() {
            f.getMechanic().updateControls(f, h);
        });

        botbar.addClass("disabled");
        botbar.off('click');

        return true;
    };

    this.updateBottom = function(f, h) {
        if (!!interceptor && !!interceptor.updateBottom)
            if (interceptor.updateBottom(f, h))
                return true;

        var scrollable = h.getElement().find(".free_height_inner_holder_container");
        var content = h.getElement().find(".container_content");
        var control = f.getWrapper().find(".matrix_controls_body .matrix_control_down");

        if (scrollable.length == 0 || (scrollable.scrollTop() + scrollable.height() >= content.height())) {
            control.removeClass("scale_down");

            return false;
        }

        control.addClass("scale_down");
        control.removeClass("disabled");

        control.off('click');
        control.click(function() {
            scrollable.stop().animate({
                scrollTop: scrollable.scrollTop() + scrollable.height()
            }, 300, 'swing', function() {
                f.getMechanic().updateControls(f, h);
            });
        });

        return true;
    };

    this.createControl = function(f, klazz, body) {
        if (!!interceptor && !!interceptor.createControl)
            if (interceptor.createControl(f, klazz, body))
                return true;

        return false;
    }

    this.createTopbar = function(f, klazz, body) {
        if (!!interceptor && !!interceptor.createTopbar)
            if (interceptor.createTopbar(f, klazz, body))
                return true;

        return false;
    }

    this.createBotbar = function(f, klazz, body) {
        if (!!interceptor && !!interceptor.createBotbar)
            if (interceptor.createBotbar(f, klazz, body))
                return true;

        return false;
    }

    this.update = function(f, c) {
        if (!!interceptor && !!interceptor.update)
            if (interceptor.update(f, c))
                return true;

        return false;
    };

    this.clear = function(f) {
        if (!!interceptor && !!interceptor.clear)
            if (interceptor.clear(f))
                return true;

        return false;
    };

    this.initialize = function(f) {
        if (!!interceptor && !!interceptor.initialize)
            if (interceptor.initialize(f))
                return true;

        return false;
    };

    var __construct = function(that) {
        self = that;

        interceptor = i;
    }(this)
}