export default function SectionHelperContainerBuilder(w, params) {
    var self = null;
    let wrapped = null;

    var sectionParameters = {
        title: "what",
        loaderColor: "#444",
        isCloseEnabled: true
    };

    this.getParams = function() {
        return sectionParameters;
    }

    this.build = function(container, ele) {
        var containerSelector = container.getContainerSelector();

        if (ele == null || typeof(ele) == 'undefined')
            ele = $("<div></div>");

        if (containerSelector.search("#") >= 0) {
            ele.attr("id", containerSelector.replace("#", ""));
        } else if (containerSelector.search(".") >= 0) {
            var splitted = containerSelector.split(".");
            splitted.forEach(function(word) {
                ele.addClass(word.replace(" ", ""));
            });
        }

        var sectionInfo = $("<section class='section_info_wrapper row'></section>");
        var title = $("<div class='section_big_title row no-margin'></div>");
        title.html(sectionParameters.title);

        sectionInfo.append(title);

        var loader = createLoader(container);
        var pending = $("<div class='section_content_pending pending row no-margin'></div>");
        pending.append($("<h3 class='value'></h3>"));
        pending.append(loader);

        var empty = $("<div class='section_content_empty empty row no-margin'></div>");
        empty.append($("<h3 class='value'></h3>"));

        var content = $("<div class='section_content row no-margin'></div>");
        content.data("parent", containerSelector);

        ele.append(sectionInfo);
        ele.append(pending);
        ele.append(empty);
        ele.append(content);

        if (!!sectionParameters.isCloseEnabled && sectionParameters.isCloseEnabled) {
            var bt = $("<i class='zmdi zmdi-chevron-right section_close_button'></i>");
            sectionInfo.append(bt);
        }

        if (wrapped != null)
            return wrapped.build(container, ele);

        return ele;
    }

    this.configure = function(container, ele) {
        ele.each(function() {
            var parent = $(this);
            var content = parent.find(container.getContentSelector());

            container.setLoading(false);
            container.setAlreadyLoaded(true);
            container.didLoad(container);

            $(window).resize(function() {
                container.didUpdate();
            });
        });

        if (wrapped != null)
            return wrapped.configure(container, ele);

        return ele;
    }

    var __construct = function (that) {
        self = that;
        wrapped = w;

        if (params != null)
            sectionParameters = params;
    }(this)

    var createLoader = function(container) {
        var loaderScript = "<div class='loading-holder'>"
            + "<div class='preloader-wrapper small active'>"
            + "<div class='spinner-layer'>"
            + "<div class='circle-clipper left'>"
            + "<div class='circle'></div>"
            + "</div>"
            + "<div class='gap-patch'>"
            + "<div class='circle'></div>"
            + "</div>"
            + "<div class='circle-clipper right'>"
            + "<div class='circle'></div>"
            + "</div>"
            + "</div>"
            + "</div>"
            + "</div>"

        var loader = $(loaderScript);

        loader.find(".circle-clipper").css(
            "border-color",
            sectionParameters.loaderColor ? sectionParameters.loaderColor : "#fff"
        );

        return loader;
    }
}