export default function DefaultPlusNavigationControls(i) {
    var self = null;
    var interceptor = null;

    this.updateLeft = function(f, c) {
        if (!!interceptor && !!interceptor.updateLeft)
            if (interceptor.updateLeft(f, c))
                return true;

        var control = f.getWrapper().find(".matrix_controls_body .matrix_control_left");
        var target = f.getHolder(c.getI(), c.getJ() - 1);

        if (target == null || target.getBuilder().getType() == 'empty') {
            control.addClass("disabled");
        } else {
            control.removeClass("disabled");

            control.data("hint", target.getTitle());

            control.off('click');
            control.click(function() {
                f.getMechanic().goLeft();
            });
        }

        return true;
    };

    this.updateRight = function(f, c) {
        if (!!interceptor && !!interceptor.updateRight)
            if (interceptor.updateRight(f, c))
                return true;

        var control = f.getWrapper().find(".matrix_controls_body .matrix_control_right");
        var target = f.getHolder(c.getI(), c.getJ() + 1);

        if (target == null || target.getBuilder().getType() == 'empty') {
            control.addClass("disabled");
        } else {
            control.removeClass("disabled");

            control.data("hint", target.getTitle());

            control.off('click');
            control.click(function() {
                f.getMechanic().goRight();
            });
        }

        return true;
    };

    this.updateTopbar = function(f, c) {
        if (!!interceptor && !!interceptor.updateTopbar)
            if (interceptor.updateTopbar(f, c))
                return true;

        var toolbar = f.getWrapper().find(".matrix_controls_body .topbar_matrix_control");

        if (c.getTopbarActivated()) {
            toolbar.removeClass("disabled");
        } else {
            toolbar.addClass("disabled");
        }

        return true;
    };

    this.updateBotbar = function(f, c) {
        if (!!interceptor && !!interceptor.updateBotbar)
            if (interceptor.updateBotbar(f, c))
                return true;

        var botbar = f.getWrapper().find(".matrix_controls_body .botbar_matrix_control");

        if (c.getBotbarActivated()) {
            botbar.removeClass("disabled");
        } else {
            botbar.addClass("disabled");
        }

        return true;
    };

    this.updateTop = function(f, c) {
        if (!!interceptor && !!interceptor.updateTop)
            if (interceptor.updateTop(f, c))
                return true;

        var control = f.getWrapper().find(".matrix_controls_body .matrix_control_up");
        var target = f.getHolder(c.getI() - 1, c.getJ());

        if (target == null || target.getBuilder().getType() == 'empty') {
            control.addClass("disabled");
        } else {
            if (c.getTopbarActivated()) {
                control.addClass("disabled");
            } else {
                control.removeClass("disabled");

                control.data("hint", target.getTitle());

                control.off('click');
                control.click(function() {
                    f.getMechanic().goUp();
                });
            }
        }

        return true;
    };

    this.updateBottom = function(f, c) {
        if (!!interceptor && !!interceptor.updateBottom)
            if (interceptor.updateBottom(f, c))
                return true;

        var control = f.getWrapper().find(".matrix_controls_body .matrix_control_down");
        var target = f.getHolder(c.getI() + 1, c.getJ());

        if (target == null || target.getBuilder().getType() == 'empty') {
            control.addClass("disabled");
        } else {
            if (c.getBotbarActivated()) {
                control.addClass("disabled");
            } else {
                control.removeClass("disabled");

                control.data("hint", target.getTitle());

                control.off('click');
                control.click(function () {
                    f.getMechanic().goDown();
                });
            }
        }
        return true;
    };

    this.createTopbar = function(f, klazz, body) {
        var c = $("<div class='matrix_control'></div>");
        var ca = $("<div class='topbar_matrix_control_action'></div>");

        ca.html("contato");

        c.addClass("topbar_matrix_control disabled");
        c.addClass(klazz);

        body.append(c);

        if (!!interceptor && !!interceptor.createTopbar)
            return interceptor.createTopbar(f, klazz, body);

        return true;
    }

    this.createBotbar = function(f, klazz, body) {
        var c = $("<div class='matrix_control'></div>");
        var ca = $("<div class='botbar_matrix_control_action'></div>");

        ca.html("contato");

        c.addClass("botbar_matrix_control disabled");
        c.addClass(klazz);

        body.append(c);

        if (!!interceptor && !!interceptor.createBotbar)
            return interceptor.createBotbar(f, klazz, body);

        return true;
    }

    this.createControl = function(f, klazz, body) {
        if (!!interceptor && !!interceptor.createControl)
            if (interceptor.createControl(f, klazz, body))
                return true;

        var c = $("<div class='matrix_control'></div>");
        var il = $("<div class='matrix_control_spin matrix_control_spin_left'></div>");
        var ir = $("<div class='matrix_control_spin matrix_control_spin_right'></div>");

        c.addClass(klazz);
        c.append(il);
        c.append(ir);

        body.append(c);

        return true;
    }

    this.update = function(f, c) {
        if (!!interceptor && !!interceptor.update)
            if (interceptor.update(f, c))
                return true;

        self.updateTopbar(f, c);
        self.updateBotbar(f, c);
        self.updateLeft(f, c);
        self.updateRight(f, c);
        self.updateTop(f, c);
        self.updateBottom(f, c);

        return true;
    };

    this.clear = function(f) {
        if (!!interceptor && !!interceptor.clear)
            if (interceptor.clear(f))
                return true;

        var w = f.getWrapper();
        var b = w.find(".matrix_controls_body");

        b.remove();

        return true;
    }

    this.initialize = function(f) {
        self.clear(f);

        var w = f.getWrapper();
        var b = $("<div class='matrix_controls_body'></div>");

        self.createTopbar(f, "topbar_matrix_control", b);
        self.createBotbar(f, "botbar_matrix_control", b);

        if (!!interceptor && !!interceptor.createControl)
            if (!interceptor.createControl(f, "matrix_control_left", b))
                self.createControl(f, "matrix_control_left", b);

        if (!!interceptor && !!interceptor.createControl)
            if (!interceptor.createControl(f, "matrix_control_right", b))
                self.createControl(f, "matrix_control_right", b);

        if (!!interceptor && !!interceptor.createControl)
            if (!interceptor.createControl(f, "matrix_control_down", b))
                self.createControl(f, "matrix_control_down", b);

        if (!!interceptor && !!interceptor.createControl)
            if (!interceptor.createControl(f, "matrix_control_up", b))
                self.createControl(f, "matrix_control_up", b);

        w.append(b);

        if (!!interceptor && !!interceptor.initialize)
            return interceptor.initialize(f);

        return true;
    }

    var __construct = function(that) {
        self = that;

        interceptor = i;
    }(this)
}