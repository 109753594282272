export default function PlusNavigationMatrixMechanic(params) {
    var facade = null;
    var self = null;
    var isVerticalScrollEnabled = false;
    var center = { i: 1, j: 1 };
    var current = null;
    var strategy = null;
    var defaultStrategy = null;
    var defaultControls = null;
    var controls = null;

    this.getVerticalScrollEnabled = function() {
        return isVerticalScrollEnabled;
    }

    this.getCenter = function() {
        return center;
    }

    this.setStrategy = function(n) {
        if (!!n)
            strategy = n;
        else
            strategy = defaultStrategy;
    }

    this.setControls = function(c) {
        var memento = controls;

        if (controls != null && c === controls)
            return;

        if (!!c)
            controls = c;
        else
            controls = defaultControls;

        if (memento === controls)
            return;

        if (facade != null)
            controls.initialize(facade);
    }

    this.setFacade = function(f) {
        facade = f;

        var wr = facade.getWrapper();

        self.setCurrent(center.i, center.j);

        controls.initialize(f);

        if (isVerticalScrollEnabled)
            self.unlockWrapperScroll();
        else
            self.lockWrapperScroll();

        $(document).keydown(function(e) {
            switch(e.which) {
                case 37:
                    self.goLeft();
                    break;

                case 38:
                    self.goUp();
                    break;

                case 39:
                    self.goRight();
                    break;

                case 40:
                    self.goDown();
                    break;

                default: return;
            }
        });

        wr.on("swipeleft", function() { self.goRight(); });
        wr.on("swiperight", function() { self.goLeft(); });
        wr.on("swipeup", function() { self.goDown(); });
        wr.on("swipedown", function() { self.goUp(); });

        var gluing = false;

        wr.bind('DOMMouseScroll mousewheel MozMousePixelScroll', function(e) {
            if (!isVerticalScrollEnabled)
                return;

            var si = Math.round(wr.scrollTop()/(wr.height()*1.0));
            var sj = Math.round(wr.scrollLeft()/(wr.width()*1.0));
            var ci = current.getI();
            var cj = current.getJ();

            var xi = si - wr.scrollTop()/(wr.height()*1.0);
            var xj = sj - wr.scrollLeft()/(wr.width()*1.0);

            var di = wr.scrollTop();
            var dj = wr.scrollLeft();

            var up = e.originalEvent.wheelDelta/(Math.abs(e.originalEvent.wheelDelta)) < 0;
            var glueUp = (up && xi < 0.15 && xi > 0.05);
            var glueDown = (!up && xi > -0.15 && xi < -0.05);
            var glueLeft = (up && xj < 0.15 && xj > 0.05);
            var glueRight = (!up && xj > -0.15 && xj < -0.05);

            if (glueUp || glueDown)
                di = si*wr.height();

            if (glueLeft || glueRight)
                dj = sj*wr.width();

            if (!gluing && (glueUp || glueDown || glueLeft || glueRight)) {
                gluing = true;
                e.stopPropagation();
                e.stopImmediatePropagation();

                wr.stop().animate({
                    scrollTop: di,
                    scrollLeft: dj
                }, 100, "swing", function() {
                    gluing = false;

                    self.updateCurrentChildScrolling();
                });
            } else {
                self.updateCurrentChildScrolling();
            }

            if (ci != si || cj != sj)
                if (si < facade.getN() && sj < facade.getM())
                    self.setCurrent(si,sj);

            return true;
        });
    };

    this.updateCurrentChildScrolling = function() {
        var sy = current.getElement().find(".free_height_inner_holder_container");
        if (sy.length > 0)
            if (current.getElement().offset().top != 0)
                sy.addClass("scroll_locked");
            else
                sy.removeClass("scroll_locked");

        var sx = current.getElement().find(".free_width_inner_holder_container");
        if (sx.length > 0)
            if (current.getElement().offset().left != 0)
                sx.addClass("scroll_locked");
            else
                sx.removeClass("scroll_locked");
    };

    this.goLeft = function() {
        strategy.goLeft(facade);
    }

    this.goRight = function() {
        strategy.goRight(facade);
    }

    this.goUp = function() {
        strategy.goUp(facade);
    }

    this.goDown = function() {
        strategy.goDown(facade);
    }

    this.go = function(i, j, duration) {
        strategy.go(facade, i,j, duration);
    }

    this.setCurrent = function(i, j) {
        current = facade.getHolder(i,j);

        if (!(current.getControls() === controls)) {
            controls.update(facade, current);

            setTimeout(function() {
                self.setStrategy(current.getNavigator());
                self.setControls(current.getControls());

                setTimeout(function() {
                    controls.update(facade, current);
                }, 50);
            }, 50);
        } else {
            self.setStrategy(current.getNavigator());
            self.setControls(current.getControls());
            controls.update(facade, current);
        }

        current.open();
    };

    this.updateControls = function(f, c) {
        controls.update(f, c);
    }

    this.getCurrent = function() {
        return current;
    };

    this.goCenter = function() {
        self.go(center.i, center.j, 100, facade);
    };

    this.lockWrapperScroll = function() {
        facade.getWrapper().css("overflow-y", "hidden");
    }

    this.unlockWrapperScroll = function() {
        facade.getWrapper().css("overflow-y", "auto");
    }

    var __construct = function(that) {
        self = that;

        if (!!params.isVerticalScrollEnabled)
            isVerticalScrollEnabled = params.isVerticalScrollEnabled;

        if (!!params.center)
            center = params.center;

        if (!!params.navigationControls) {
            defaultControls = params.navigationControls;
        } else {
            defaultControls = new DefaultPlusNavigationControls(new ChildNavigationControls());
        }

        if (!!params.navigationStrategy) {
            defaultStrategy = params.navigationStrategy;
        } else {
            defaultStrategy = new DefaultPlusNavigationStrategy(new ChildNavigationStrategy());
        }

        self.setStrategy(defaultStrategy);
        self.setControls(defaultControls);
    }(this)
}