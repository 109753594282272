export default function DefaultPlusNavigationStrategy(i) {
    var self = null;
    var interceptor = null;

    this.goLeft = function(f) {
        if (!!interceptor && !!interceptor.goLeft)
            if (interceptor.goLeft(f))
                return true;

        var c = f.getMechanic().getCurrent();

        if (c == null)
            self.goCenter();
        else
            self.go(f, c.getI(), c.getJ() - 1);
    };

    this.goRight = function(f) {
        if (!!interceptor && !!interceptor.goRight)
            if (interceptor.goRight(f))
                return true;

        var c = f.getMechanic().getCurrent();

        if (c == null)
            self.goCenter();
        else
            self.go(f, c.getI(), c.getJ() + 1);
    };

    this.goUp = function(f) {
        if (!!interceptor && !!interceptor.goUp)
            if (interceptor.goUp(f))
                return true;

        var c = f.getMechanic().getCurrent();

        if (c == null)
            self.goCenter();
        else
            self.go(f, c.getI() - 1, c.getJ());
    };

    this.goDown = function(f) {
        if (!!interceptor && !!interceptor.goDown)
            if (interceptor.goDown(f))
                return true;

        var c = f.getMechanic().getCurrent();

        if (c == null)
            self.goCenter();
        else
            self.go(f, c.getI() + 1, c.getJ());
    };

    this.goCenter = function(f) {
        if (!!interceptor && !!interceptor.goCenter)
            if (interceptor.goCenter(f))
                return true;

        self.go(
            f,
            f.getMechanic().getCenter().i,
            f.getMechanic().getCenter().j,
            100
        );
    }

    this.go = function(f, i,j, duration) {
        if (!!interceptor && !!interceptor.go)
            if (interceptor.go(f, i, j, duration))
                return true;

        var m = f.getMechanic();
        var wrapper = f.getWrapper();
        var target = f.getHolder(i, j);
        var current = m.getCurrent();
        var center = m.getCenter();

        if (target == null || target.getBuilder().getType() == "empty") {
            console.warn("Movimento bloqueado.");

            return false;
        }

        if (wrapper.hasClass("moving"))
            return false;

        wrapper.addClass("moving");

        var fj = -current.getJ() + target.getJ();
        var fi = -current.getI() + target.getI();
        var cj = 0;
        var ci = 0;

        if (m.getVerticalScrollEnabled()) {
            var ndi = wrapper.scrollTop()/(wrapper.height()*1.0);
            var ndj = wrapper.scrollLeft()/(wrapper.width()*1.0);

            ci = Math.round(ndi)*wrapper.height();
            cj = Math.round(ndj)*wrapper.width();
        } else {
            ci = wrapper.scrollTop();
            cj = wrapper.scrollLeft();
        }

        m.setCurrent(i,j);

        wrapper.stop().animate({
            scrollLeft: cj,
            scrollTop:  ci
        }, 50, "swing", function() {
            var gj = wrapper.scrollLeft() + fj*wrapper.width();
            var gi = wrapper.scrollTop() + fi*wrapper.height();

            wrapper.animate({
                scrollLeft: gj,
                scrollTop:  gi
            }, typeof(duration) == 'undefined' ? 300 : duration, "swing", function(){
                wrapper.removeClass("moving");

                if (j != center.j)
                    m.lockWrapperScroll();
                else if (m.getVerticalScrollEnabled())
                    m.unlockWrapperScroll();
            });
        });

        return true;
    };

    var __construct = function(that) {
        self = that;

        interceptor = i;
    }(this)
}