export default function MatrixInnerHolderComponent(params) {
    var self = null;
    var identifier = null;
    var elem = null;
    var builder = null;
    var navigator = null;
    var controls = null;
    var isTopbarActivated = false;
    var isBotbarActivated = false;

    var data = {};

    var containerSelector = null;
    var contentSelector = null;
    var contentHolder = null;
    var containerIdentifier = "inner_holder_container";

    var i = null;
    var j = null;

    var listeners = [];
    var containers = [];

    this.getI = function() {
        return i;
    }

    this.getJ = function() {
        return j;
    }

    this.set = function(oi, oj) {
        i = oi;
        j = oj;

        elem.attr("data-i", i);
        elem.attr("data-j", j);
    }

    this.matches = function(oi, oj) {
        if ((oi == i) && (oj == j))
            return true;

        return false;
    }

    this.getData = function() {
        return data;
    }

    this.setTitle = function(t) {
        self.getBuilder().setTitle(self, t);
    }

    this.getTitle = function() {
        return self.getBuilder().getParams().title;
    }

    this.getIdentifier = function() {
        return identifier;
    }

    this.setIdentifier = function(i) {
        identifier = i;
    }

    this.addListener = function(listener) {
        listeners.push(listener);
    }

    this.removeListener = function(listener) {
        listeners.removeItem(listener);
    }

    this.addContainer = function(container) {
        containers.push(container);

        contentHolder.append(container.getElement());
        container.getElement().addClass(containerIdentifier);
        container.setHolder(self);
    }

    this.removeContainer = function(container) {
        containers.removeItem(container);
        container.getElement().removeClass(containerIdentifier);

        container.setHolder(null);
    }

    this.getContainers = function() {
        return containers;
    }

    this.getFirstContainer = function() {
        return containers.length == 0 ? null : containers[0];
    }

    this.clear = function() {
        contentHolder.find("." + containerIdentifier).remove();
    }

    this.clearExcept = function(except) {
        contentHolder.find("." + containerIdentifier).not(except).remove();
    }

    this.getElement = function() {
        return elem;
    }

    this.setBuilder = function(b) {
        builder = b;
    }

    this.getBuilder = function() {
        return builder;
    }

    this.setControls = function(c) {
        controls = c;
    }

    this.getControls = function() {
        return controls;
    }

    this.setNavigator = function(n) {
        navigator = n;
    }

    this.getNavigator = function() {
        return navigator;
    }

    this.getBotbarActivated = function() {
        return isBotbarActivated;
    }

    this.setBotbarActivated = function(ita) {
        isBotbarActivated = ita;
    }


    this.getTopbarActivated = function() {
        return isTopbarActivated;
    }

    this.setTopbarActivated = function(ita) {
        isTopbarActivated = ita;
    }

    this.getContentSelector = function() {
        return contentSelector;
    }

    this.getContainerSelector = function() {
        return containerSelector;
    }

    this.setContainerSelector = function(cs) {
        containerSelector = cs;

        elem = $(containerSelector);
    }

    this.setContentSelector = function(cs) {
        contentSelector = cs;

        contentHolder = elem.find(contentSelector);
    }

    this.create = function() {
        elem = builder.build(self);

        self.setContentSelector(contentSelector);

        containers.forEach(function(container){
            contentHolder.append(container.getElement());
        });

        listeners.forEach(function(listener) {
            listener.onCreateView(self);
        });
    }

    this.initialize = function() {
        listeners.forEach(function(listener) {
            listener.onInitView(self);
        });

        elem.data("component", self);
    }

    var facade = null;
    var focused = false;
    var opened = false;

    this.setFocused = function(f) {
        focused = f;

        if (elem != null) {
            if (focused)
                elem.addClass("focused");
            else
                elem.removeClass("focused");
        }

        listeners.forEach(function(listener) {
            if (focused)
                listener.viewDidFocus(self);
            else
                listener.viewDidBlur(self);
        });
    }

    this.getOpened = function() {
        return opened;
    }

    this.getFocused = function() {
        return focused;
    }

    this.getFacade = function() {
        return facade;
    }

    this.open = function() {
        var container = containers[0];

        if (!!container && !container.isAlreadyLoaded()) {
            //facade.setLoading(true);
            setTimeout(function() { container.getProxy().execute(); }, 250);
        }
    }

    this.close = function() {
        facade.getMechanic().remove(self);
    }

    this.reload = function() {
        var container = containers[0];

        if (!!container) {
            //facade.setLoading(true);
            setTimeout(function() { container.getProxy().execute(); }, 250);
        }
    }

    this.didAttachToWindow = function(wf) {
        facade = wf;

        builder.afterAttach(self);
    }

    this.didOpen = function(mechanic) {
        builder.animateIn(self);

        opened = true;

        listeners.forEach(function(listener) {
            listener.viewDidOpen(self);
        });
    }

    this.didClose = function(mechanic) {
        builder.animateOut(self);

        opened = false;

        listeners.forEach(function(listener) {
            listener.viewDidClose(self);
        });
    }


    var __construct = function (that) {
        self = that;
        identifier = params.identifier;
        builder = params.builder;
        navigator = params.navigator;
        controls = params.controls;
        isTopbarActivated = !!params.isTopbarActivated;
        isBotbarActivated = !!params.isBotbarActivated;
        self.setContainerSelector(params.containerSelector);
        self.setContentSelector(params.contentSelector);
    }(this)
}